// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import singlepackage from '../src/Pages/SinglePackage/SinglePackage';


LazyLoad.init();
Subpage.init();

const routes = new Router({
	homepage,
	singlepackage,
});

document.addEventListener("DOMContentLoaded", function() {
	routes.loadEvents();
});