import Cookie from '../../src/Components/Cookie/Cookie';
import sal from 'sal.js';
import $ from 'jquery';

export default {

	init() {

		Cookie.init();

		// -- mobile menu -- //

			var body = document.querySelector('body');

			var mobileMenu = body.querySelector('section.MobileMenu');
			var mobileMenuContent = mobileMenu.querySelector('.mobileMenu__content');
			var hamburger = body.querySelector('header .right__hamburger');

			hamburger.addEventListener('click', function() {

				hamburger.classList.add('is-active');
				mobileMenu.classList.add('active');
				body.classList.add('disableScroll');

			});

			mobileMenu.addEventListener('click', function() {

				hamburger.classList.remove('is-active');
				mobileMenu.classList.remove('active');
				body.classList.remove('disableScroll');

			});	

			mobileMenuContent.addEventListener('click', function(e) {

				e.stopPropagation();

			});

		// -- END -- //


		// -- nav item scroll -- //

			var navItems = document.querySelectorAll('.nav__item');

			if( navItems ) {

				navItems.forEach( function( item, index ) {

					if( item.getAttribute('href').charAt(0) == '#' && document.querySelector( item.getAttribute('href') ) ) {

						item.addEventListener('click', function(e) {

							e.preventDefault();

							$('html ,body').animate({'scrollTop': document.querySelector( item.getAttribute('href') ).offsetTop - document.querySelector('header').offsetHeight }, 'smooth');

							if( item.closest('.MobileMenu') ) {

								document.querySelector('.MobileMenu').classList.remove('active');
								document.querySelector('body').classList.remove('disableScroll');

							}

						});

					}

				});

			}

		// -- END -- //

		
		// -- sal animate -- //

			function salAnimation() {

				// initial by class: sal-animate
				const salObject = new sal({
					disable: true,
				});
				var contents = document.querySelectorAll('.sal-animate');

				if( contents ) {

					contents.forEach( function( content,index ) {

						content.setAttribute( "data-sal", "fade" );
						content.setAttribute( "data-sal-duration", "500" );
						content.setAttribute( "data-sal-easing", "ease-out-back" );
						content.setAttribute( "data-sal-delay", "300" );

					});

				}

				salObject.reset({
					disable: false,
				});

			}
			salAnimation();

		// -- END -- //

	},
	finalize() {

	}

}