import PackagesSection from '../../Sections/PackagesSection/PackagesSection';
import ContactForm from '../../Components/ContactForm/ContactForm';
import HeaderSection from '../../Sections/HeaderSection/HeaderSection';

export default {
	init() {

		var container = document.querySelector('main.Homepage');

		if( container ) {

    		PackagesSection.init();
    		ContactForm.init();
    		HeaderSection.init();

    	}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
