import ContactForm from '../../Components/ContactForm/ContactForm';
import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('main.SinglePackage');

		if( container ) {

			ContactForm.init();

			// -- calc new price -- //

				var basicPrice = container.getAttribute('data-price');

				var savedPrice = container.querySelector('.singlePackage__modules .module__save');
				if( savedPrice ) {
					var savedPriceNumber = parseFloat(savedPrice.getAttribute('data-saved'));
				}

				var currentPriceContainer = container.querySelector('.singlePackage__modules .module__row .row__price span');

				var currentPrice = 0;
				var modulesAllChecked = true;
				function calcNewPrice() {

					currentPrice = parseFloat(basicPrice);
					modulesAllChecked = true;

					modulesCheckbox.forEach( function( single2,index2 ) {

						if( single2.checked ) {

							currentPrice += parseFloat(single2.value);

						} else {

							modulesAllChecked = false;

						}

					});

					if( modulesAllChecked && savedPrice ) {

						currentPrice -= savedPriceNumber;

					}

					currentPriceContainer.innerHTML = currentPrice+' zł';

				}

			// -- END -- //



			// -- modules on click -- //

				var modulesContainer = container.querySelector('.singlePackage__modules');

				if( modulesContainer ) {

					var modulesCheckbox = container.querySelectorAll('.singlePackage__modules .grid__single input');					

					modulesCheckbox.forEach( function( single, index ) {

						single.addEventListener('change', function() {

							calcNewPrice();

						});

					});

				}

			// -- END -- //


			// -- modules select all button -- //

				function selectAllModules() {

					var modulesContainer = container.querySelector('.singlePackage__modules');

					if( modulesContainer ) {

						var selectButton = modulesContainer.querySelector('.module__save button');
						var modules = modulesContainer.querySelectorAll('.module__grid .grid__single');

						if( selectButton ) {

							selectButton.addEventListener('click', function() {

								modules.forEach( function( single, index ) {

									single.querySelector('input').checked = true;
									calcNewPrice();

								});

							});

						}


					}						

				}
				selectAllModules();

			// -- END -- //


			// -- modules contact form -- //

				var body = document.querySelector('body');

				var form = container.querySelector('.singlePackage__form');

				if( form ) {

					var contactForm = form.querySelector('.ContactForm');
					var button = container.querySelector('.module__form');

					button.addEventListener('click', function() {

						form.classList.add('active');
						body.classList.add('disableScroll');

						let selectedModules = "";

						document.querySelectorAll('.singlePackage__modules .module__grid .grid__single input').forEach( function( single,index ) {

							if( single.checked ) {

								selectedModules = selectedModules + '\n-' + single.closest('.grid__single').querySelector('.single__name').innerText;

							}

						});

						console.log(selectedModules);

						if(selectedModules) {
							container.querySelectorAll('.contactForm__field textarea').forEach( function( single,index ) {
								single.value = 'Dzień dobry,\n\nW związku z przedstawioną przez Pana ofertą pakietu "' + $('.header__title').text() + '" wybrane zostałe następujące moduły:\n' + selectedModules + ' \n\no łącznej wartości ' + $('.row__price span').text() + ' netto. Bardzo proszę o informację zwrotną o zasadach płatności oraz terminie realizacji.';
							});
						}

					});

					form.addEventListener('click', function() {

						form.classList.remove('active');
						body.classList.remove('disableScroll');

					});

					contactForm.addEventListener('click', function(e) {

						e.stopPropagation();

					});

				}

			// -- END -- //


			// -- Modules more -- //

				var more = container.querySelectorAll('.single__more');

				if( more.length > 0 ) {

					function is_touch_device() { 
						try {  
							document.createEvent("TouchEvent");  
							return true;  
						} catch (e) {  
							return false;  
						}  
					}	
						
					document.addEventListener('click', function() {

						if( is_touch_device() ) {

							more.forEach( function( single,index ) {

								single.querySelector('.more__tooltip').classList.remove('active');

							});

						}

					});

					more.forEach( function( single,index ) {

						single.addEventListener('click', function(e) {

							e.stopPropagation();
							e.preventDefault();

							if( is_touch_device() ) {
								
								more.forEach( function( single2, index2 ) {

									single2.querySelector('.more__tooltip').classList.remove('active');

								});

								if( single.querySelector('.more__tooltip').classList.contains('active') ) {

									single.querySelector('.more__tooltip').classList.remove('active');

								} else {

									single.querySelector('.more__tooltip').classList.add('active');

								}

							}

						});

					});

				}

			// -- END -- //

		}

	},
	finalize() {

	}

}	