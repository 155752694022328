import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('section.HeaderSection');

		if( container ) {

			var scrollButton = container.querySelector('.headerSection__content .Button');
			var section;
			var header = document.querySelector('header');

			if( scrollButton ) {

				if( scrollButton.getAttribute('href').split('#')[1] ) {

					section = document.querySelector('section#'+scrollButton.getAttribute('href').split('#')[1]);

					if( section ) {

						scrollButton.addEventListener('click', function( e ) {

							e.preventDefault();

							$('html ,body').animate({'scrollTop': section.offsetTop + header.offsetHeight }, 'smooth');

						});

					}					

				}

			}

		}

	},
	finalize() {

	}

}