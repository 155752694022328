import slick from 'slick-carousel';
import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('section.PackagesSection');
		
		if( container ) {

			// -- slick carousel -- //

				var carousel = container.querySelector('.row__carousel');
				var buttonNext = container.querySelector('.left__control .control__button--next');
				var buttonPrev = container.querySelector('.left__control .control__button--prev');

				$(carousel).slick({
					slidesToShow: 3,
					slidesToScroll: 3,
					rows: 0,
					arrows: false,
					autoplay: true,
					autoplaySpeed: 2000,
					responsive: [
						{
							breakpoint: 1361,
							settings: {
								dots: true,
							}
						},
						{
							breakpoint: 801,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2,
								dots: true
							}
						},
						{
							breakpoint: 401,
							settings: {
								slidesToScroll: 1,
								slidesToShow: 1,
								dots: false,
							}
						}
					]
				});

				if( buttonNext ) {
					buttonNext.addEventListener('click', function() {

						$(carousel).slick('slickNext');

					});	
				}

				if( buttonPrev ) {
					buttonPrev.addEventListener('click', function() {

						$(carousel).slick('slickPrev');

					});	
				}

			// -- END -- //


		}

	},
	finalize() {

	}

}